.appBarDropDownText {
  color: #000 !important;
}
.navBgColor {
  background-color: #fff;
  border-bottom: 1px solid #dee2e6 !important;
}
.navBgColorAdmin {
  background-color: #fff;
  border-bottom: 1px solid #dee2e6 !important;
}
.btn.btn-success {
  background-color: #e8444f !important;
  border-radius: 4px;
  border: solid #e8444f 1px;
}
.btn.btn-info {
  background-color: #e8444f !important;
  border-radius: 4px;
  border: solid #e8444f 1px;
}
.btn.btn-primary {
  background-color: #e8444f !important;
}
.pagination .page-item.active .page-link {
  background-color: #e8444f !important;
}
.ant-slider-track {
  background-color: #e8444f !important;
}
.ant-slider-handle {
  border: solid 2px #e8444f;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #e8444f !important;
}
.ant-radio-inner::after {
  background-color: #e8444f !important;
}
.ant-pagination-item-active {
  border-color: #e8444f !important;
}
#tabBar-link a {
  color: #e8444f !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #e8444f;
  border-color: #e8444f;
}
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #e8444f !important;
  background-color: #e8444f !important;
}

.text-primary {
  color: #e8444f !important;
}

.primary {
  color: #e8444f !important;
}

.primary:hover {
  color: #e8444f !important;
}

.primaryBackground {
  background-color: #e8444f !important;
}

.btnPrimary {
  background-color: #e8444f !important;
  border-color: #e8444f !important;
}

.btnPrimary:hover {
  background-color: #e8444f !important;
}

.ant-progress-status-success
  .ant-progress-inner:not(.ant-progress-circle-gradient)
  .ant-progress-circle-path {
  stroke: #e8444f !important;
}

.ant-progress-inner:not(.ant-progress-circle-gradient)
  .ant-progress-circle-path {
  stroke: #e8444f !important;
}

.ant-spin-dot-item {
  background: #e8444f !important;
}
