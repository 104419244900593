.caret-up-cont {
  position: relative;
  bottom: -12px;
}

.caret-up-cont svg,
.caret-down-cont svg {
  color: rgba(100, 100, 100, 0.4);
}

.caret-up-cont:hover svg,
.caret-down-cont:hover svg {
  color: rgba(100, 100, 100, 0.7);
}

.main-tbl tr.data-row td {
  padding-bottom: 0px !important;
  height: 130px;
  min-width: 140px;
}
