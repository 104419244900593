.active-tab {
  border-bottom: 2px solid #eb9c31 !important;
}
.login-card {
  background-color: #fff !important;
}
.loginTitleColor {
  color: #3a404d;
}
.loginBtn {
  line-height: 1.2 !important;
  letter-spacing: 0 !important;
  font-weight: 600 !important;
  padding: 16px 26px !important;
  border: 1px solid #e8444f !important;
  background: #e8444f !important;
  color: #fff !important;
  border-radius: 2px !important;
  width: 100%;
}
.loginBtn:focus {
  outline: none !important;
}
.bgImgLoginAdmin {
  /* background-image: url("../src/views/Login/images/bgImgAdmin.jpg"); */
  /* background: red; fallback color */
  /* background: linear-gradient( to top, rgba(204, 28, 28, 0.055), rgb(21, 18, 189),violet,indigo); */
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(240deg, #233857 0%, #eb4450 100%);
}
.bgImgLogin {
  height: 100vh;
  overflow: hidden;
  background-image: linear-gradient(180deg, #233857 0%, #3686b1 100%);
  background-color: transparent;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.tableHeaderBg {
  background-color: #0c4a82 !important;
  color: white;
}
.site-tree-search-value {
  color: #f50;
}
.cover {
  object-fit: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.active-subtab {
  background: #aaaaaa91;
}
.bg-gray {
  background: #cccccc82 !important;
}
.fontSmall {
  font-size: 14px;
}
.titleImg {
  object-fit: cover;
  width: 60px;
  height: 60px;
  border-radius: 10px;
}
.table-img-box {
  object-fit: cover;
  width: 65px;
  height: 75px;
  border-radius: 10px;
}

select {
  font-size: 14px !important;
  padding: 0 !important;
  color: #afafaa !important;
}

select option {
  color: black;
}
select option:first-child {
  color: lightgrey;
}

.primary-dark {
  color: #033e6a !important;
}

#paginationdiv {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
#paginationdiv ul {
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0px !important;
  max-width: 100vw;
  border-radius: 20px !important;
  border: 1px solid lightgrey;
  background-color: white;
  overflow: auto;
}
/* #paginationdiv ul li:first-child {
  margin-right: 20px;
  border-radius: 20px;
} */
/* #paginationdiv ul li a:first-child{
      content: ;
  } */
/* #paginationdiv ul li:last-child {
  margin-left: 20px;
  border-radius: 20px;
} */
/* #paginationdiv ul li:nth-child(2) {
  border-radius: 20px 0 0 20px;
}
#paginationdiv ul li:nth-last-child(2) {
  border-radius: 0px 20px 20px 0px;
} */
#paginationdiv ul li {
  padding: 10px 10px;
  background-color: white;
  cursor: pointer;
}
#paginationdiv ul li a {
  text-decoration: none;
  padding: 10px 15px;
  box-shadow: none;
}
.active {
  background-color: #0c4a82 !important;
  color: white !important;
}
#paginationdiv ul li:not(.active):hover {
  background-color: lightgrey;
  color: black !important;
}
#paginationdiv ul li a:focus {
  outline: none;
}
.border-thick-left {
  border-left: solid rgb(2, 123, 180) !important;
}

.border-thick-left:hover {
  border-left-color: rgb(4, 179, 253) !important;
}
.arrow-up {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-bottom: 5px solid black;
}

.text-8-rem {
  font-size: 0.8rem !important;
}
.bg-gradient {
  background: linear-gradient(
    to right top,
    #051937,
    #004066,
    #006c8a,
    #009999,
    #00c595
  );
}

.vh-100vh {
  height: 100vh;
}

/* for table limit input select */
.custom-select {
  width: 10rem !important;
  padding-left: 0.5rem !important;
}

/* for input file type custom */

.filtTypeInput[type="file"] {
  display: none;
}

.Toastify__toast--success {
  padding: 25px !important;
  background: #6ca76e !important ;
}

/* style for 404 page  */

#notfound {
  position: relative;
  height: 100vh;
  background: #030005;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 767px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
}

.notfound .notfound-404 {
  position: relative;
  height: 180px;
  margin-bottom: 20px;
  z-index: -1;
}

.notfound .notfound-404 h1 {
  font-family: "Montserrat", sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 224px;
  font-weight: 900;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: -12px;
  color: #030005;
  text-transform: uppercase;
  text-shadow: -1px -1px 0px #577fda, 1px 1px 0px #a53592;
  letter-spacing: -20px;
}

.notfound .notfound-404 h2 {
  font-family: "Montserrat", sans-serif;
  position: absolute;
  left: 0;
  right: 0;
  top: 110px;
  font-size: 42px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0px 2px 0px #ff0000;
  letter-spacing: 13px;
  margin: 0;
}

.notfound a {
  font-family: "Montserrat", sans-serif;
  display: inline-block;
  text-transform: uppercase;
  color: #112d7c;
  text-decoration: none;
  border: 2px solid;
  background: transparent;
  padding: 10px 40px;
  font-size: 14px;
  font-weight: 700;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.notfound a:hover {
  color: #8400ff;
}

@media only screen and (max-width: 767px) {
  .notfound .notfound-404 h2 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 h1 {
    font-size: 182px;
  }
}

/* end style for 404 page */
.ant-tree-list {
  min-height: auto;
  max-height: 250px !important;
}
.card-h {
  height: 350px;
}
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #1890ff !important;
  background-color: initial !important;
}
.zIndex-100 {
  z-index: 100;
}
.fa,
.fas {
  font-weight: 700;
  /* font-size: smaller; */
  color: #4b90ff;
}
.anticon-question-circle svg {
  margin-top: -5px;
}
.target-table.table th {
  padding-right: 4px !important;
  padding-left: 4px !important;
}
table.target-table td {
  padding: 0px !important;
  padding-left: 4px !important;
  vertical-align: middle;
}
.company-logo {
  max-height: 90px;
  min-width: 55px;
  height: auto;
  position: relative;
  text-align: center;
  overflow: hidden;
}
.skeleton-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  vertical-align: top;
  background: hsla(0, 0%, 74.5%, 0.2);
  width: 80px;
  height: 86px;
  line-height: 96px;
}
.skeleton-image .skeleton-image-svg {
  width: 48px;
  height: 48px;
  line-height: 48px;
  max-width: 192px;
  max-height: 192px;
}
.skeleton-image .skeleton-image-path {
  fill: #bfbfbf;
}

.ant-progress-circle.ant-progress-status-success .ant-progress-text {
  color: rgba(0, 0, 0, 0.65) !important;
}

.cp {
  cursor: pointer;
}
.drop_lg {
  width: 350px !important;
}
